// import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './less/main.less'
import _axios from './plugins/axios'
// import VConsole from 'vconsole';
// let vconsole = new VConsole();

const app = createApp(App)
import localforage from "localforage";
localforage.config({
    name: 'db_cache2'
});

import Vue3TouchEvents from "vue3-touch-events";
// app.use(vconsole)
app.use(Vue3TouchEvents);
app.config.globalProperties.$localForage = localforage;
app.config.globalProperties.$axios = _axios;


app.use(router)
app.use(store)

app.mount('#app')
