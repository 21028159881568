<template>
  <div class="tabbar">
    <div class="tab-item" @click="handleRouter('profile')">
      <img v-show="$route.path==='/pet'" src="/images/tabbar/pet2.png" alt="">
      <img v-show="$route.path!=='/pet'" src="/images/tabbar/pet1.png" alt="">
    </div>
    <div class="tab-item" @click="handleRouter('')">
      <img v-show="$route.path==='/'" src="/images/tabbar/map2.png" alt="">
      <img v-show="$route.path!=='/'" src="/images/tabbar/map1.png" alt="">
    </div>
    <div class="tab-item" @click="handleRouter('mint')">
      <img v-show="$route.path==='/mint'" src="/images/tabbar/mint2.png" alt="">
      <img v-show="$route.path!=='/mint'" src="/images/tabbar/mint1.png" alt="">
    </div>
    <div class="tab-item" @click="handleRouter('me')">
      <img v-show="$route.path==='/me'" src="/images/tabbar/me2.png" alt="">
      <img v-show="$route.path!=='/me'" src="/images/tabbar/me1.png" alt="">
    </div>
  </div>
</template>

<script>
  import wx from "weixin-js-sdk";
export default {
  name: "Tab-bar",
  methods:{
    handleRouter(name){
      if(name==''){
        this.$router.push(name)
      }else{
        const ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          wx.miniProgram.getEnv((res) => {
            if (res.miniprogram) {
              console.log('在小程序内');
            } else {
              console.log('不在小程序内');
              return
            }
          });
        } else {
          console.log('不在微信浏览器内');
        }

        // 小程序跳转方法
        wx.miniProgram.switchTab({
          url:`/pages/tabbar/${name}/index`
        });
      }

    }
  },
}
</script>

<style scoped lang="less">
.tabbar{
  position: fixed;
  z-index: 1;
  bottom: 10px;
  left: 0;
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  .tab-item{
    img{
      display: block;
      width:24vw;
    }
  }
}
</style>
