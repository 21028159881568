import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/pet',
    name: 'pet',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pet.vue')
  },
  {
    path: '/',
    name: 'map',
    component: () => import(/* webpackChunkName: "about" */ '../views/Map.vue')
  },
  {
    path: '/mint',
    name: 'mint',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mint.vue')
  },
  {
    path: '/me',
    name: 'me',
    component: () => import(/* webpackChunkName: "about" */ '../views/Me.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
