import wx from "weixin-js-sdk";

function showPhoto(img_index,img_arr,img_key,zan_type){
    const ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        wx.miniProgram.getEnv((res) => {
            if (res.miniprogram) {
                console.log('在小程序内');
            } else {
                console.log('不在小程序内');
                return
            }
        });
    } else {
        console.log('不在微信浏览器内');
    }

    // 小程序跳转方法
    wx.miniProgram.navigateTo({
        url:`/pages/album/index?img_index=`+img_index+'&img_arr='+img_arr+'&img_key='+img_key+'&zan_type='+zan_type,
    });
}

function navigateTo(url){
    const ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        wx.miniProgram.getEnv((res) => {
            if (res.miniprogram) {
                console.log('在小程序内');
            } else {
                console.log('不在小程序内');
                return
            }
        });
    } else {
        console.log('不在微信浏览器内');
    }

    // 小程序跳转方法
    wx.miniProgram.navigateTo({
        url:url
    });
}

export { showPhoto,navigateTo }