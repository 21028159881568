<template>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
    <router-view/>

  <TabBar></TabBar>
</template>

<script>
// @ is an alias to /src
import TabBar from '@/components/TabBar.vue'

export default {
  name: 'HomeView',
  components:{
    TabBar
  }

}
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
</style>
