"use strict";
import axios from "axios";
import {navigateTo} from "@/utils/wxMiniProgram"
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: "https://cwxq.modijiaoyu.com/",
  timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error && error.response) {

      // Message.destroy();
      let _code=JSON.parse(error.response.data.code);
      if(_code==1007){
        navigateTo(`/pages/index/index?reLogin=true`)
      }
    }
    return Promise.reject(error);
  }
);


export default _axios;
